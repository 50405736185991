import React from 'react';
import { colorTokens } from '@/components/ui/colorTokens';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconKnowely: FCIcon = (props) => {
  const fillColor = colorTokens['bg-neutral'];

  return (
    <BaseIcon
      width="24"
      height="24"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="33" cy="33" r="33" fill="#7852F4" />
      <path d="M17.2104 51.1433C16.4988 53.2346 17.6172 55.507 19.7086 56.2186C21.8 56.9303 24.0723 55.8118 24.7839 53.7204L30.0178 38.3396L42.1862 48.4798C43.8833 49.8941 46.4055 49.6648 47.8198 47.9677C49.234 46.2706 49.0047 43.7483 47.3076 42.3341L38.9951 35.4069L47.3076 28.4798C49.0047 27.0656 49.234 24.5433 47.8198 22.8462C46.4055 21.1491 43.8833 20.9198 42.1862 22.3341L32.8037 30.1528L38.0076 14.86C38.7193 12.7686 37.6008 10.4963 35.5094 9.78464C33.418 9.07298 31.1457 10.1915 30.4341 12.2828L17.2104 51.1433Z" fill={fillColor} />
    </BaseIcon>
  );
};
